import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import Campus from "./pages/Campus/Campus";
import Vehicles from "./pages/Vehicle/Vehicles";
import DefineFeeClassWise from "./pages/DefineFeeClassWise";
import SendSmsToStaff from "./pages/SendSmsToStaff";
import AddCampusAdmin from "./pages/AddCampusAdmin";
import Classes from "./pages/Classes";
import AddVehicle from "./pages/Vehicle/Form/AddVehicle";
import AddBankForm from "./pages/Bank/Form/AddBankForm";
import AddCampus from "./pages/Campus/Form/AddCampus";

// modules routes
import Users from "./pages/Users/Users.js";
// import Campus from './modules/Regestration/campus/Campus'
import ShowBanks from "./pages/Bank/AddBank";
import AddBank from "./pages/Bank/Form/AddBankForm";
import Staff from "./modules/Regestration/staff/Staff";
import Student from "./modules/Regestration/student/Student";
import AddStudent from "./pages/Student/Form/AddStudent";
import PromoteStudent from "./pages/Student/PromoteStudent/PromoteStudent";

import ExamList from "./pages/Student/Exam/ExamList";
import UpdateExams from "./pages/Student/Exam/UpdateExams.js";
import ShowDateSheet from "./pages/Student/Exam/showdatesheet";

import AssignSubject from "./pages/Student/AssignSubjects/AssignSubject";

import Hostel from "./modules/Regestration/hostel/Hostel";
import Vehicle from "./modules/Regestration/vehicle/Vehicle";
import CampusClasses from "./pages/AddCampusWiseClass/CampusClasses";
import AddClassCampus from "./pages/AddCampusWiseClass/Form/AddClassCampus";
import Concession from "./pages/Concession/Concession";
import AddConcession from "./pages/Concession/Form/AddConcession";
import CardIssueStudents from "./pages/Cardissuestudents/CardIssueStudents";
import GenrateFee from "./pages/GenrateFee/GenrateFee";
import GenrateStudentWiseFee from "./pages/GenrateStudentWiseFee/GenrateStudentWiseFee";
import ViewStudent from "./pages/Student/ViewStudent/ViewStudent";
import GenrateClassWiseFee from "./pages/GenrateClassWiseFee/GenrateClassWiseFee";
import GenerateFee from "./pages/GenerateStudentFee/GenerateFee.js";
import DefineCampusFee from "./pages/DefineCampusFee/DefineCampusFee";
import GenerateCampusWiseFee from "./pages/GenrateCampusWiseFee/GenerateCampusWiseFee";
import FindStudents from "./pages/FindStudents/FindStudents";
import CampusFeeChallan from "./pages/CampusFeeChallan/CampusFeeChallan";
import CampusClassChallan from "./pages/CampusClassChallan/CampusClassChallan";
import SectionFeeChallan from "./pages/SectionFeeChallan/SectionFeeChallan";
import PrintCampusFeeChallan from "./pages/CampusFeeChallan/PrintCampusFeeChallan/PrintCampusFeeChallan";
import PrintClassChallans from "./pages/CampusClassChallan/PrintClassChallans/PrintClassChallans";
import PrintAllSectionChallan from "./pages/SectionFeeChallan/PrintAllSectionChallan/PrintAllSectionChallan";
import FeeReceive from "./pages/FeeReceive/FeeReceive";
import ShowAllStudents from "./pages/ShowAllStudents/ShowAllStudents";
import PrintCampusPaidChallans from "./pages/CampusFeeChallan/PrintCampusPaidChallans/PrintCampusPaidChallans";
import PrintCampusUnpaidChallans from "./pages/CampusFeeChallan/PrintCampusUnpaidChallans/PrintCampusUnpaidChallans";
import PrintSectionPaidChallans from "./pages/SectionFeeChallan/PrintSectionPaidChallans/PrintSectionPaidChallans";
import PrintSectionUnpaidChallans from "./pages/SectionFeeChallan/PrintSectionUnpaidChallans/PrintSectionUnpaidChallans";
// import CardIssueStudents from "./pages/Cardissuestudents/Cardissuestudents";
import DefineFeeTypes from "./pages/DefineFeeTypes/DefineFeeTypes";
import CustomChallan from "./pages/CustomChallan/CustomChallan";
import ProtectedRoutes from "./ProtectedRoutes";
import ShowRegisterdStaff from "./pages/Staff-Module/StaffRegister/StaffRegister";
import StaffRegister from "./pages/Staff-Module/StaffRegister/addNewStaff";
import Sessions from "./pages/Sessions/Sessions";
import Hostels from "./pages/Hostel/Hostels";
import Payscale from "./pages/Staff-Module/DefinePayScale/Payscale";
import Sections from "./pages/Sections/Sections";
import AppointedStaff from "./pages/Staff-Module/AppointedStaff/AppointedStaff";
import ViewStaffDetails from "./pages/Staff-Module/AppointedStaff/ViewStaffDetails/ViewStaffDetails";
import GenerateBulkSalary from "./pages/Staff-Module/GenerateBulkSalary/GenerateBulkSalary";
import FilterSalary from "./pages/Staff-Module/FilterSalary/FilterSalary";
import AccountVocher from "./pages/AccountsVocher/accountsvocher";
import AddVocherPostingAccount from "./pages/AddVocherPostingAccount/addVocherPostingAccount";
import Chart_Of_Account from "./pages/Accounts_Chart/Acount_Chart";
import CampusStudents from "./pages/CampusStudents/CampusStudents";
import DNVoucher from "./pages/DNVoucher/DNVoucher";
import DayEndVoucher from "./pages/DayEndVoucher/DayEndVoucher";
import SearchFeeChallan from "./pages/SearchFeeChallan/Searchfeechallan";
import PaySalary from "./pages/Staff-Module/PaySalary/PaySalary";
// import BalanceSheet from "./pages/Report-Module/BalanceSheet/BalanceSheet";
import SalaryReport from "./pages/Report-Module/SalaryReport/SalaryReport";
import FeesReport from "./pages/Report-Module/FeesReport/FeesReport";
import ExpensesReport from "./pages/Report-Module/ExpensesReport/ExpensesReport";
import StaffListStatusWise from "./pages/Staff-Module/StaffListStatusWise/StaffListStatusWise";
import NotActiveStudent from "./pages/NotActiveStudent/NotActiveStudent";
import StudentWisePrint from "./pages/StudentWisePrint/StudentWisePrint";
import ViewStudentPrintWise from "./pages/ViewStudentPrintWise/ViewStudentPrintWise";
import MonthlyChallanPrint from "./pages/MonthlyChallanPrint/MonthlyChallanPrint";
import PrintStudentWise from "./pages/PrintStudentWise/PrintStudentWise";
import PrintStudentFeebill from "./pages/Report-Module/PrintStudentFeebill/PrintStudentFeebill";
import HostelStudentReport from "./pages/Report-Module/HostelStudentReport/HostelStudentReport";
import RegisterStaffListReport from "./pages/Report-Module/RegisterStaffList/RegisterStaffListReport";
import AppointedStaffReport from "./pages/Report-Module/AppointedStaffReport/AppointedStaffReport";
import ListWithBankData from "./pages/Report-Module/ListWithBankData/ListWithBankData";
import StaffListOverAll from "./pages/Report-Module/StaffListOverAll/StaffListOverAll";
import DemandPaySheet from "./pages/Report-Module/DemandPaySheet/DemandPaySheet";
import DailyScroll from "./pages/Report-Module/DailyScroll/DailyScroll.js";
import AccountLedger from "./pages/Report-Module/AccountLedger/AccountLedger";
import BankScroll from "./pages/Report-Module/BankScroll/BankScroll";
import FeeReturn from "./pages/FeeReturn/FeeReturn";
import MarkAttendance from "./pages/Student Attendance/MarkAttendance";
import ReceiveFee from "./pages/CampusFeeChallan/ReceiveFee/ReceiveFee";
import Result from "./pages/Student/Result/Result";
import StudentWiseResult from "./pages/Student/StudentWiseResult/StudentWiseResult";
import ResultSequence from "./pages/Student/ResultSequence/ResultSequence.js";
import SingleStudentResult from "./pages/Student/SingleStudentResult/SingleStudentResult";
import StudentDueFee from "./pages/Report-Module/StudentDueFee/StudentDueFee";
import FeeDetails from "./pages/Report-Module/FeeDetails/FeeDetails";
import ScrollFee from "./pages/Report-Module/DailyScrollFee/ScrollFee";
import DailyTransaction from "./pages/DailyTransaction/DailyTransaction";
import OnlyRegistered from "./pages/Student/OnlyRegisteredStudents/OnlyRegistered";
import UploadPicture from "./pages/Student/UploadPicture/UploadPicture";
import HostelStudents from "./pages/Hostel/HostelStudents";
import HostelAdmit from "./pages/Hostel/HostelAdmit/HostelAdmit";
import StudentMessaging from "./pages/Messaging/StudentMessaging/StudentMessaging";
import StaffMessaging from "./pages/Messaging/StaffMessaging/StaffMessaging";
import LogReport from "./pages/Messaging/LogReport/LogReport";
import PaidUnpaid from "./pages/Report-Module/PaindUnpaid/PaidUnpaid";
import StaffMember from "./pages/StaffMember/StaffMember";
import StaffLoan from "./pages/Staff-Module/StaffLoan/StaffLoan";
import StaffPayDetails from "./pages/Staff-Module/StaffPayDetails/StaffPayDetails";
import StudentConcession from "./pages/Student/StudentConcession/StudentConcession";
import StudentTotalAdm from "./pages/Student/StudentTotalAdm/StudentTotalAdm";
import TotalStaff from "./pages/Staff-Module/TotalStaff/TotalStaff";
import GpFund from "./pages/Staff-Module/GpFund/GpFund";
import Eobi from "./pages/Staff-Module/Eobi/Eobi";
import SalarySlip from "./pages/Staff-Module/SalarySlip/SalarySlip";
import PrintAttendanceSectionWise from "./pages/Student Attendance/PrintAttendanceSectionWise.js";
import StudentProgress from "./pages/Report-Module/StudentProgress/StudentProgress";
import ProfitLoss from "./pages/ProfitLoss/ProfitLoss";
import TrialBalance from "./pages/TrialBalance/TrialBalance";
import IncomeExpenditure from "./pages/IncomeExpenditure/IncomeExpenditure";
import ComparativeExpense from "./pages/ComparativeExpense/ComparativeExpense";
import StudentStrength from "./pages/StudentStrength/StudentStrength";
import BalanceSheet from "./pages/BalanceSheet/BalanceSheet";
import FeeSummary from "./pages/FeeSummary/FeeSummary";
import CashBank from "./pages/CashBank/CashBank";
import MonthlyExpense from "./pages/MonthlyExpense/MonthlyExpense";
import FeeeRecievedMonth from "./pages/FeeeRecievedMonth/FeeeRecievedMonth";
import SingleStudentMonth from "./pages/SingleStudentMonth/SingleStudentMonth";
import CheckList from "./pages/CheckList/CheckList";
import NewAdmissions from "./pages/NewAdmissions/NewAdmissions";
import ColumnList from "./pages/ColumnLIst/ColumnList";
import BankPaySheet from "./pages/BankPaySheet/BankPaySheet";
import ReceivingPaySheet from "./pages/ReceivingPaySheet/ReceivingPaySheet";
import MonthlyPaySheet from "./pages/MonthlyPaySheet/MonthlyPaySheet";
import SupportForm from "./pages/Support/SupportForm";
import Support from "./pages/Support/Support";
import Notifications from "./pages/Notifications/Notifications";
import MessageAllocation from "./pages/MessageAllocation/MessageAllocation.js";

import UnitSettings from "./pages/UnitSettings/UnitSettings.js";
import ReceivePartialFee from "./pages/CampusFeeChallan/ReceivePartialFee";
import RollBack from "./pages/CampusFeeChallan/RollBack.js";

import StaffCertificate from "./pages/Certificates/StaffCertificate";
import ExperienceCertificate from "./pages/Certificates/ExperienceCertificate";
import HopeCertificate from "./pages/Certificates/HopeCertificate";
import SchoolLeavingCertificate from "./pages/Certificates/SchoolLeavingCertificate";
import MigrationCertificate from "./pages/Certificates/MigrationCertificate";
import CertificateReport from "./pages/Certificates/CertificateReport.js";

import AdvancedFeeReceive from "./pages/AdvancedFeeReceive/AdvancedFeeReceive.js";
import StudentMissingInfo from "./pages/StudentMissingInformation/StudentMissingInfo";
import StudentAllInfo from "./pages/StudentAllInfo/StudentAllInfo";
import GenerateDiscountFee from "./pages/GenerateStudentFee/GenerateDiscountFee.js";

import VerifyDN from "./pages/Report-Module/VerifyDN/VerifyDN.js";
import AttendanceSummary from "./pages/Student Attendance/AttendanceSummary";
import StaffGPFund from "./pages/Staff-Module/GpFund/StaffGPFund";
import SearchStaff from "./pages/Staff-Module/SearchStaff/SearchStaff";
import SearchStudent from "./pages/Student/SearchStudent/SearchStudent";
import YearEnd from "./pages/TrialBalance/YearEnd";
import FirmWiseReport from "./pages/TrialBalance/FirmWiseReport.js";
import GrossSalarySheet from "./pages/GrossSalarySheet/GrossSalarySheet";
import ProjectedMonthlyIncome from "./pages/ProjectedMonthlyIncome/ProjectedMonthlyIncome";
import StudentFigureDateWise from "./pages/StudentFigureDateWise/StudentFigureDateWise";
import BranchesUrl from "./pages/BranchManagement/BranchesUrl.js";

import { useSelector } from "react-redux";
import { PremissionChecker } from "../services/PremissionChecker.js";
import Roles from "./pages/Roles/Roles.js";
import ActionReport from "./pages/ActionReport/ActionReport.js";
import SystemDashboard from "./pages/SystemDashboard/SystemDashboard.js";
import ChangePassword from "./modules/UserProfile/ChangePassword.js";
import MonthlyBankScroll from "./pages/Report-Module/MonthlyBankScroll/MonthlyBankScroll.js";
import NotGeneratedFeeMonthWise from "./pages/Student/NotGeneratedFeeMonthWise/NotGeneratedFeeMonthWise.js";
import DefineClass from "./pages/AddCampusWiseClass/DefineClass/DefineClass.js";
import DefineSection from "./pages/AddSectionToCampus/DefineSection/DefineSection.js";
import DefineSubject from "./pages/Student/DefineSubject/DefineSubject.js";
import DailyTestSystem from "./pages/Student/TestManagement/DailyTestSystem.js";
import EnterTest from "./pages/Student/TestManagement/EnterTest.js";
import AddHomeWork from "./pages/Student/HomeWork/AddHomeWork.js";
import UpdateHomeWork from "./pages/Student/HomeWork/UpdateHomeWork.js";
import BranchNotification from "./pages/BranchManagement/BranchNotification.js";
import BranchResources from "./pages/BranchManagement/BranchResources.js";
import DefineGrade from "./pages/Student/DefineGrade/DefineGrade.js";
import MonthlyPaySummary from "./pages/Staff-Module/MonthlyPaySummary/MonthlyPaySummary.js";
import ProvidentFundReport from "./pages/Staff-Module/ProvidentFundReport/ProvidentFundReport.js";
import StudentResources from "./pages/Student/StudentResources/StudentResources.js";
import DefineExam from "./pages/Student/DefineExam/DefineExam.js";
import StudentHistory from "./pages/Student/StudentHistory/StudentHistory.js";
import { appStatus } from "../services/config.js";
import GenerateMiscFee from "./pages/GenerateStudentFee/GenerateMiscFee.js";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  const appVersion = "1.1.5"; // Update this version after each new deployment
  const storedVersion = localStorage.getItem("appVersion");

  if (storedVersion !== appVersion) {
    alert("Software version has been updated successfully!");

    // Clear LocalStorage and SessionStorage
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("appVersion", appVersion);

    // Optional: Reload the page to ensure everything is cleared
    window.location.reload();
  }

  if (appStatus == "0") {
    alert(
      "Your hosting plan has expired. Please contact the administrator to renew your subscription."
    );
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }

  const user = useSelector((state) => state.user.user);

  const history = useHistory();

  const SESSION_TIMEOUT = 20 * 60 * 1000; // 30 minutes

  const setSessionTimeout = () => {
    const expiresAt = new Date().getTime() + SESSION_TIMEOUT;
    localStorage.setItem("session_expires_at", expiresAt);
  };

  const checkSessionTimeout = () => {
    const now = new Date().getTime();
    const expiresAt = localStorage.getItem("session_expires_at");
    return now > expiresAt;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (checkSessionTimeout()) {
        // Clear user session, e.g., clear localStorage and update state
        localStorage.clear(); // Adjust according to how you handle auth state
        history.push("/login"); // Redirect to login page
      }
    }, 1000 * 60); // check every minute

    return () => clearInterval(interval);
  }, [history]);

  function useActivityDetector(history) {
    useEffect(() => {
      const events = ["click", "mousemove", "keydown"];
      const resetTimeout = () => setSessionTimeout();

      events.forEach((event) => window.addEventListener(event, resetTimeout));

      return () => {
        events.forEach((event) =>
          window.removeEventListener(event, resetTimeout)
        );
      };
    }, [history]);
  }

  function useSessionTimeout(history) {
    useActivityDetector(history); // Reset timeout on activity

    useEffect(() => {
      const interval = setInterval(() => {
        if (checkSessionTimeout()) {
          localStorage.clear(); // Adjust according to how you handle auth state
          history.push("/login"); // Redirect to login page
        }
      }, 1000 * 60); // check every minute

      return () => clearInterval(interval);
    }, [history]);
  }

  useSessionTimeout(history);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/systemdashboard" />
        }

        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.voucher_handling")
              ? user.roles[0].name
              : "",
          ]}
          path="/account_vocher"
          component={AccountVocher}
        />
        {/* <ContentRoute path="/add_vocher_posting_account" component={AddVocherPostingAccount} /> */}

        {/* new pages*/}
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.system_dashboard")
              ? user.roles[0].name
              : "",
          ]}
          path="/systemdashboard"
          component={SystemDashboard}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.unit_settings")
              ? user.roles[0].name
              : "",
          ]}
          path="/unit-settings"
          component={UnitSettings}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.change_password")
              ? user.roles[0].name
              : "",
          ]}
          path="/changepassword"
          component={ChangePassword}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.receive_partial_fee")
              ? user.roles[0].name
              : "",
          ]}
          path="/receivepartialfee"
          component={ReceivePartialFee}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.roll_back")
              ? user.roles[0].name
              : "",
          ]}
          path="/RollBack"
          component={RollBack}
        />

        {/* Certificate */}
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.hope_certificate")
              ? user.roles[0].name
              : "",
          ]}
          path="/hopeCertificate"
          component={HopeCertificate}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.staff_certificate")
              ? user.roles[0].name
              : "",
          ]}
          path="/StaffCertificate"
          component={StaffCertificate}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.experience_certificate")
              ? user.roles[0].name
              : "",
          ]}
          path="/ExperienceCertificate"
          component={ExperienceCertificate}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.school_leaving_certificate")
              ? user.roles[0].name
              : "",
          ]}
          path="/SchoolLeavingCertificate"
          component={SchoolLeavingCertificate}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.migration_certificate")
              ? user.roles[0].name
              : "",
          ]}
          path="/MigrationCertificate"
          component={MigrationCertificate}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.certificate_report")
              ? user.roles[0].name
              : "",
          ]}
          path="/CertificateReport"
          component={CertificateReport}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_missing_info")
              ? user.roles[0].name
              : "",
          ]}
          path="/StudentMissingInfo"
          component={StudentMissingInfo}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_all_info")
              ? user.roles[0].name
              : "",
          ]}
          path="/StudentAllInfo"
          component={StudentAllInfo}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.advanced_fee_receive")
              ? user.roles[0].name
              : "",
          ]}
          path="/advancedFeeReceive"
          component={AdvancedFeeReceive}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.genrate_student_wise_fee")
              ? user.roles[0].name
              : "",
          ]}
          path="/generate-discount-fee"
          component={GenerateDiscountFee}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.verifydn")
              ? user.roles[0].name
              : "",
          ]}
          path="/verifydn"
          component={VerifyDN}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.pay_staff_gp_fund")
              ? user.roles[0].name
              : "",
          ]}
          path="/paystaffgpfund"
          component={StaffGPFund}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.search_student")
              ? user.roles[0].name
              : "",
          ]}
          path="/findstudent"
          component={SearchStudent}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.search_staff")
              ? user.roles[0].name
              : "",
          ]}
          path="/searchstaff"
          component={SearchStaff}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.year_end")
              ? user.roles[0].name
              : "",
          ]}
          path="/YearEnd"
          component={YearEnd}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.firm_wise_report")
              ? user.roles[0].name
              : "",
          ]}
          path="/FirmWiseReport"
          component={FirmWiseReport}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.gross_salary_sheet")
              ? user.roles[0].name
              : "",
          ]}
          path="/gross-salary-sheet"
          component={GrossSalarySheet}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.project_monthly_income")
              ? user.roles[0].name
              : "",
          ]}
          path="/project-monthly-income"
          component={ProjectedMonthlyIncome}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_figure_date_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/student-figure-date-wise"
          component={StudentFigureDateWise}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.branches_url")
              ? user.roles[0].name
              : "",
          ]}
          path="/branches-url"
          component={BranchesUrl}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.branch_notification")
              ? user.roles[0].name
              : "",
          ]}
          path="/branch-notification"
          component={BranchNotification}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.branch_resources")
              ? user.roles[0].name
              : "",
          ]}
          path="/branch-resources"
          component={BranchResources}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.no_generated_fee_month_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/not-generated-fee-month-wise"
          component={NotGeneratedFeeMonthWise}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.campus_list")
              ? user.roles[0].name
              : "",
          ]}
          path="/campus"
          component={Campus}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.create_campus")
              ? user.roles[0].name
              : "",
          ]}
          path="/addcampus"
          render={() => <AddCampus show={true} />}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.banks_list")
              ? user.roles[0].name
              : "",
          ]}
          path="/bank"
          component={ShowBanks}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.add_bank")
              ? user.roles[0].name
              : "",
          ]}
          path="/addbank"
          render={() => <AddBank show={true} />}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.define_classes")
              ? user.roles[0].name
              : "",
          ]}
          path="/define-classes"
          component={DefineClass}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.define_sections")
              ? user.roles[0].name
              : "",
          ]}
          path="/define-sections"
          component={DefineSection}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.define_subjects")
              ? user.roles[0].name
              : "",
          ]}
          path="/define-subjects"
          component={DefineSubject}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.define_grade_system")
              ? user.roles[0].name
              : "",
          ]}
          path="/define-grade-system"
          component={DefineGrade}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.define_exam")
              ? user.roles[0].name
              : "",
          ]}
          path="/define-exam"
          component={DefineExam}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.add_remove_class")
              ? user.roles[0].name
              : "",
          ]}
          path="/campasclasses"
          component={CampusClasses}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.add_remove_class")
              ? user.roles[0].name
              : "",
          ]}
          path="/addcampusclass"
          component={AddClassCampus}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.add_bank")
              ? user.roles[0].name
              : "",
          ]}
          path="/addbankfrom"
          component={AddBankForm}
        />
        {/* module routes */}
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.users") ? user.roles[0].name : "",
          ]}
          path="/users"
          component={Users}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.day_end_voucher")
              ? user.roles[0].name
              : "",
          ]}
          path="/dnvoucher"
          component={DNVoucher}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.staff_list_status_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/staff-list-status-wise"
          component={StaffListStatusWise}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.chart_of_accounts")
              ? user.roles[0].name
              : "",
          ]}
          path="/chart_of_account"
          component={Chart_Of_Account}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.vehicles")
              ? user.roles[0].name
              : "",
          ]}
          path="/vehicle"
          component={Vehicle}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.concession")
              ? user.roles[0].name
              : "",
          ]}
          path="/concession"
          component={Concession}
        />
        <ContentRoute
          role={["Super Admin"]}
          path="/addconcession"
          component={AddConcession}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.active_students")
              ? user.roles[0].name
              : "",
          ]}
          path="/showallstudents"
          component={ShowAllStudents}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.registered_students")
              ? user.roles[0].name
              : "",
          ]}
          path="/onlyregisteredstudents"
          component={OnlyRegistered}
        />

        <ContentRoute
          path="/definefeeclasswise"
          component={DefineFeeClassWise}
        />
        <ContentRoute path="/staff" component={Staff} />
        <ContentRoute path="/sendsmstostaff" component={SendSmsToStaff} />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.other_students")
              ? user.roles[0].name
              : "",
          ]}
          path="/student"
          component={Student}
        />
        {/* <ContentRoute
          role={[PremissionChecker(user, 'side_bar.other_students') ? user.roles[0].name : '']}
          path="/student"
          component={Student}
        /> */}
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.roles") ? user.roles[0].name : "",
          ]}
          path="/roles"
          component={Roles}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.admit_student")
              ? user.roles[0].name
              : "",
          ]}
          path="/addnewstudent"
          render={() => <AddStudent show={true} />}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.promote_students")
              ? user.roles[0].name
              : "",
          ]}
          path="/studentpromote"
          component={PromoteStudent}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.message_allocation")
              ? user.roles[0].name
              : "",
          ]}
          path="/message-allocation"
          component={MessageAllocation}
        />

        <ContentRoute
          role={["Campus"]}
          path="/staff-member"
          component={StaffMember}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_check_list_overall")
              ? user.roles[0].name
              : "",
          ]}
          path="/not-active-student"
          component={NotActiveStudent}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_list_11_column")
              ? user.roles[0].name
              : "",
          ]}
          path="/columnList"
          component={ColumnList}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.print_fee_challan")
              ? user.roles[0].name
              : "",
          ]}
          path="/student-wise-print"
          component={StudentWisePrint}
        />
        <ContentRoute
          role={["Super Admin", "Head Office", "Campus"]}
          path="/view-student-wise-print"
          component={ViewStudentPrintWise}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.add_show_exam")
              ? user.roles[0].name
              : "",
          ]}
          path="/examlist"
          component={ExamList}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.daily_test_system")
              ? user.roles[0].name
              : "",
          ]}
          path="/daily-test-system"
          component={DailyTestSystem}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.enter_test")
              ? user.roles[0].name
              : "",
          ]}
          path="/enter-test"
          component={EnterTest}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.app_notifications")
              ? user.roles[0].name
              : "",
          ]}
          path="/notifications"
          component={Notifications}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.add_home_work")
              ? user.roles[0].name
              : "",
          ]}
          path="/add-home-work"
          component={AddHomeWork}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.update_home_work")
              ? user.roles[0].name
              : "",
          ]}
          path="/update-home-work"
          component={UpdateHomeWork}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_resources")
              ? user.roles[0].name
              : "",
          ]}
          path="/student-resources"
          component={StudentResources}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.app_support")
              ? user.roles[0].name
              : "",
          ]}
          path="/support"
          component={Support}
        />
        <ContentRoute
          role={["Super Admin", "Head Office", "Campus"]}
          // role={["Head Office", "Super Admin"]}
          path="/campusSupport/:id"
          component={SupportForm}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.update_exam")
              ? user.roles[0].name
              : "",
          ]}
          path="/update-exams"
          component={UpdateExams}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.date_sheet_list")
              ? user.roles[0].name
              : "",
          ]}
          path="/datesheet"
          component={ShowDateSheet}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.enter_results")
              ? user.roles[0].name
              : "",
          ]}
          path="/result"
          component={Result}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_subjects_assignment")
              ? user.roles[0].name
              : "",
          ]}
          path="/assignsubject"
          component={AssignSubject}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.add_edit_student_picture")
              ? user.roles[0].name
              : "",
          ]}
          path="/pictures"
          component={UploadPicture}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.result_list_section_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/studentwiseresult"
          component={StudentWiseResult}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.result_card_sequences")
              ? user.roles[0].name
              : "",
          ]}
          path="/result-sequence"
          component={ResultSequence}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.print_result_admission_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/singlestudentresult"
          component={SingleStudentResult}
        />

        <ContentRoute role="" path="/viewstudent/:id" component={ViewStudent} />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.admission_card")
              ? user.roles[0].name
              : "",
          ]}
          path="/cardissuestudents"
          component={CardIssueStudents}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.fee_received_summary")
              ? user.roles[0].name
              : "",
          ]}
          path="/fee-summary"
          component={FeeSummary}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.fee_received_category_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/fee-recieved-month"
          component={FeeeRecievedMonth}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.profit_and_loss_report")
              ? user.roles[0].name
              : "",
          ]}
          path="/profit-loss"
          component={ProfitLoss}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.trial_balance")
              ? user.roles[0].name
              : "",
          ]}
          path="/trial-balance"
          component={TrialBalance}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.comparative_expense_report")
              ? user.roles[0].name
              : "",
          ]}
          path="/comparative-expense"
          component={ComparativeExpense}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.balance_sheet")
              ? user.roles[0].name
              : "",
          ]}
          path="/balance-sheet"
          component={BalanceSheet}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.cash_and_bnk_balances")
              ? user.roles[0].name
              : "",
          ]}
          path="/cash-bank"
          component={CashBank}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.monthly_expense_date_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/date-wise-expense"
          component={MonthlyExpense}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.income_and_expenditure_report")
              ? user.roles[0].name
              : "",
          ]}
          path="/income-expenditure"
          component={IncomeExpenditure}
        />
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/printfeebill"
          component={PrintStudentFeebill}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.due_fee_report")
              ? user.roles[0].name
              : "",
          ]}
          path="/due-fee"
          component={StudentDueFee}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_strength")
              ? user.roles[0].name
              : "",
          ]}
          path="/student-strength"
          component={StudentStrength}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_history")
              ? user.roles[0].name
              : "",
          ]}
          path="/history-of-student"
          component={StudentHistory}
        />

        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/student-fee-Progress"
          component={StudentProgress}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.fee_concessions_report")
              ? user.roles[0].name
              : "",
          ]}
          path="/student-concession-fee"
          component={StudentConcession}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_total_admissions")
              ? user.roles[0].name
              : "",
          ]}
          path="/student-total-admission"
          component={StudentTotalAdm}
        />
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/paid-unpaid"
          component={PaidUnpaid}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_fee_details")
              ? user.roles[0].name
              : "",
          ]}
          path="/fee-details"
          component={FeeDetails}
        />
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/scrollFee"
          component={ScrollFee}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.transactions_report")
              ? user.roles[0].name
              : "",
          ]}
          path="/daily-transactions"
          component={DailyTransaction}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.hostel_student_list")
              ? user.roles[0].name
              : "",
          ]}
          path="/hostelstudentreport"
          component={HostelStudentReport}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.hostel_students")
              ? user.roles[0].name
              : "",
          ]}
          path="/hostel-students"
          component={HostelStudents}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.hostel_admission")
              ? user.roles[0].name
              : "",
          ]}
          path="/hostel-admit"
          component={HostelAdmit}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.staff_list_with_bank_details")
              ? user.roles[0].name
              : "",
          ]}
          path="/listwithbankdata"
          component={ListWithBankData}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.staff_list_category_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/stafflistoverall"
          component={StaffListOverAll}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.demand_pay_sheet")
              ? user.roles[0].name
              : "",
          ]}
          path="/demandpaysheet"
          component={DemandPaySheet}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.receive_fee")
              ? user.roles[0].name
              : "",
          ]}
          path="/campusfeerecive"
          component={ReceiveFee}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.refund_fee")
              ? user.roles[0].name
              : "",
          ]}
          path="/feereturn"
          component={FeeReturn}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.mark_attendance_or_discipline")
              ? user.roles[0].name
              : "",
          ]}
          path="/markattendance"
          component={MarkAttendance}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.print_attendance_section_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/print-attendance-section-wise"
          component={PrintAttendanceSectionWise}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.attendance_summary")
              ? user.roles[0].name
              : "",
          ]}
          path="/attendance-summary"
          component={AttendanceSummary}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.registered_staff")
              ? user.roles[0].name
              : "",
          ]}
          path="/registerstafflistreport"
          component={RegisterStaffListReport}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.appointed_staff")
              ? user.roles[0].name
              : "",
          ]}
          path="/appointedstafflistreport"
          component={AppointedStaffReport}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.staff_figures")
              ? user.roles[0].name
              : "",
          ]}
          path="/total-staff"
          component={TotalStaff}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.staff_gp_fund_details")
              ? user.roles[0].name
              : "",
          ]}
          path="/gp-fund"
          component={GpFund}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.provident_fund_report")
              ? user.roles[0].name
              : "",
          ]}
          path="/provident-fund-report"
          component={ProvidentFundReport}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.print_salary_slip")
              ? user.roles[0].name
              : "",
          ]}
          path="/salary-slip"
          component={SalarySlip}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.staff_eobi_details")
              ? user.roles[0].name
              : "",
          ]}
          path="/eobi"
          component={Eobi}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_check_list")
              ? user.roles[0].name
              : "",
          ]}
          path="/check-list"
          component={CheckList}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.student_check_list_date_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/new-admission-list"
          component={NewAdmissions}
        />
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/genratefee"
          component={GenrateFee}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.sms_log_report")
              ? user.roles[0].name
              : "",
          ]}
          path="/log-report"
          component={LogReport}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.send_message_student")
              ? user.roles[0].name
              : "",
          ]}
          path="/student-messaging"
          component={StudentMessaging}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.send_message_staff")
              ? user.roles[0].name
              : "",
          ]}
          path="/staff-messaging"
          component={StaffMessaging}
        />
        {/* <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/genratestudentwisefee"
          component={GenrateStudentWiseFee}
        /> */}
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.add_update_fee")
              ? user.roles[0].name
              : "",
          ]}
          path="/definecampusfee"
          component={DefineCampusFee}
        />
        {/* <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/genratecampuswisefee"
          component={GenerateCampusWiseFee}
        /> */}

        {/* <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/genrateclasswisefee"
          component={GenrateClassWiseFee}
        /> */}
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.generate_fee")
              ? user.roles[0].name
              : "",
          ]}
          path="/generate-fee"
          component={GenerateFee}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.generate_misc_fee")
              ? user.roles[0].name
              : "",
          ]}
          path="/generate-misc-fee"
          component={GenerateMiscFee}
        />

        {/* <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/challansearch"
          component={SearchFeeChallan}
        /> */}

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.campus_fee_challan")
              ? user.roles[0].name
              : "",
          ]}
          path="/campusfeechallan"
          component={CampusFeeChallan}
        />
        <ContentRoute
          role={["Head Office", "Super Admin", "Campus"]}
          path="/printmonthlychallan"
          component={MonthlyChallanPrint}
        />
        {/* <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/campusclasschallan"
          component={CampusClassChallan}
        /> */}
        {/* <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/sectionfeechallan"
          component={SectionFeeChallan}
        /> */}
        {/* <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/findstudent"
          component={FindStudents}
        /> */}
        {/* <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/feereceive"
          component={FeeReceive}
        /> */}
        {/* <ContentRoute role={["Head Office", "Super Admin"]} role={["Head Office", "Super Admin"]} path="/generatecustomchallan" component={CustomChallan} /> */}
        {/* Printer Routes */}
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/printcampusfeechallan"
          component={PrintCampusFeeChallan}
        />
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/printcampusclasschallan"
          component={PrintClassChallans}
        />
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/printsectionchallan"
          component={PrintAllSectionChallan}
        />
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/printcampusunpaidchallans"
          component={PrintCampusUnpaidChallans}
        />
        <ContentRoute
          role={["Head Office", "Super Admin", "Campus"]}
          path="/printstudentwisechallans"
          component={PrintStudentWise}
        />
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/printcampuspaidchallans"
          component={PrintCampusPaidChallans}
        />
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/printsectionpaidchallans"
          component={PrintSectionPaidChallans}
        />
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/printsectionunpaidchallans"
          component={PrintSectionUnpaidChallans}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.define_fee_types")
              ? user.roles[0].name
              : "",
          ]}
          path="/definefeetypes"
          component={DefineFeeTypes}
        />
        <ContentRoute path="/addcampusadmin" component={AddCampusAdmin} />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.pay_scale_handling")
              ? user.roles[0].name
              : "",
          ]}
          path="/payscale"
          component={Payscale}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.hostels")
              ? user.roles[0].name
              : "",
          ]}
          path="/hostel"
          component={Hostels}
        />
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/classes"
          component={Classes}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.active_staff")
              ? user.roles[0].name
              : "",
          ]}
          path="/appointedstaff"
          component={AppointedStaff}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.staff_loans")
              ? user.roles[0].name
              : "",
          ]}
          path="/staff-loan"
          component={StaffLoan}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.staff_pay_details")
              ? user.roles[0].name
              : "",
          ]}
          path="/staff-pay"
          component={StaffPayDetails}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.registered_staff")
              ? user.roles[0].name
              : "",
          ]}
          path="/showstaff"
          component={ShowRegisterdStaff}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.bank_pay_sheet")
              ? user.roles[0].name
              : "",
          ]}
          path="/bank-pay-sheet"
          component={BankPaySheet}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.receiving_pay_sheet")
              ? user.roles[0].name
              : "",
          ]}
          path="/receiving-pay-sheet"
          component={ReceivingPaySheet}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.monthly_pay_sheet")
              ? user.roles[0].name
              : "",
          ]}
          path="/monthly-pay-sheet"
          component={MonthlyPaySheet}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.monthly_pay_summary")
              ? user.roles[0].name
              : "",
          ]}
          path="/monthly-pay-summary"
          component={MonthlyPaySummary}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.add_staff")
              ? user.roles[0].name
              : "",
          ]}
          path="/registerstaff"
          render={() => <StaffRegister show={true} />}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.sessions")
              ? user.roles[0].name
              : "",
          ]}
          path="/sessions"
          component={Sessions}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.add_remove_section")
              ? user.roles[0].name
              : "",
          ]}
          path="/sections"
          component={Sections}
        />

        <ContentRoute path="/viewemploye/:id" component={ViewStaffDetails} />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.generate_salary")
              ? user.roles[0].name
              : "",
          ]}
          path="/bulkgeneratesalary"
          component={GenerateBulkSalary}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.pay_generated_salary")
              ? user.roles[0].name
              : "",
          ]}
          path="/paysalary"
          component={PaySalary}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.updated_generated_salary")
              ? user.roles[0].name
              : "",
          ]}
          path="/filtersalary"
          component={FilterSalary}
        />

        {/* <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/balancesheet"
          component={BalanceSheet}
        /> */}
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/salaryreport"
          component={SalaryReport}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.action_report")
              ? user.roles[0].name
              : "",
          ]}
          path="/action-report"
          component={ActionReport}
        />
        <ContentRoute
          role={["Head Office", "Super Admin"]}
          path="/feesreport"
          component={FeesReport}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.sms_log_report")
              ? user.roles[0].name
              : "",
          ]}
          path="/expensesreport"
          component={ExpensesReport}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.print_fee_challan_month_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/single-student"
          component={SingleStudentMonth}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.daily_scroll_bank_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/dailyscroll"
          component={DailyScroll}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.monthly_bank_scroll")
              ? user.roles[0].name
              : "",
          ]}
          path="/monthlybankscroll"
          component={MonthlyBankScroll}
        />

        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.account_ledger")
              ? user.roles[0].name
              : "",
          ]}
          path="/account-ledger"
          component={AccountLedger}
        />
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.daily_scroll_bank_wise")
              ? user.roles[0].name
              : "",
          ]}
          path="/bankscroll"
          component={BankScroll}
        />
        {/* <ContentRoute path="/campusstudents" component={CampusStudents} /> */}
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
